<template>
  <div class="company-dropdown" v-if="visible">
    <div v-if="loading">
      <loading :loading="true" />
    </div>
    <div class="company-dropdown-content" v-else>
      <b-row>
        <b-col cols="1">
          {{ $t("buttons.create") }}
        </b-col>

        <b-col cols="11">
          <a
            href="#"
            v-if="text"
            @click.prevent="click({ name: text, logo: null, key: null })"
          >
            <span class="company-text">{{ text }} </span>
          </a>
          <hr />
        </b-col>
        <hr />
      </b-row>

      <a
        href="#"
        v-for="item in companies"
        :key="item.key"
        @click.prevent="click(item)"
      >
        <b-row style="display: flex; align-items: center; margin-bottom: 20px">
          <b-col cols="1" style="display: flex; justify-content: center">
            <img
              v-if="item.logo"
              :src="CDN_IMAGE_PREFIX + item.logo + '?size=50xauto'" width="50"
            />
            <img style="height: 30px" v-else src="/img/company-logo.svg" />
          </b-col>

          <b-col cols="11">
            <span>{{ item.name }}</span>
          </b-col>
        </b-row>
      </a>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["value", "text"],
  computed: {
    ...mapGetters("public", ["companies", "loading"]),
  },
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    text(value) {
      this.$store.dispatch("public/COMPANY_SEARCH", { text: value });
    },
  },

  methods: {
    click(item) {
      this.visible = false;
      this.$emit("input", item);
    },
    setVisible(visible) {
      this.visible = visible;
    },
  },
};
</script>