<template>
  <div class="step-1-modal-div">
    <step-header/>
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <loading :loading="loading" />
      <div v-show="!loading" class="step-content" id="stepContent">
        <div class="container">
          <div class="login-topbar px-4 py-2">
            <b-row class="new-row">
              <b-col cols="6">
                <img src="/img/bludots.svg " alt />
              </b-col>
              <b-col cols="6" class="text-right">
                <div class="item">
                  <a href="#" @click.prevent="cancelStep">
                    <span class="icon">
                      <v-icon :icon="['fac', 'close']" />
                    </span>
                  </a>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="row justify-content-center">
            <div class="col-8">
              <!--jimir3x-->
              <form
                class="register-steps__step-1-form"
                @submit.prevent="handleSubmit(formSubmit)"
              >
                <!--jimir3x-->
                <div class="avatar-upload d-flex align-items-center my-5 zoom">
                  <img :src="avatar" class="mr-4" alt />
                  <div class="w-75">
                    <b-form-file
                      id="file"
                      class="inputfile"
                      @change="onFileChange"
                      accept="image/jpeg, image/png"
                    ></b-form-file>
                    <label
                      for="file"
                      :v-b-popover.hover.bottom="$t('auth.step1.-picture-po')"
                      
                      :title="$t('auth.step1.-picture-po-title')"
                    >
                      {{ $t('buttons.upload-pctr') }}
                      <span>
                        <v-icon :icon="['fac', 'camera']" />
                      </span>
                    </label>
                  </div>
                </div>
                <div class="form-group mb-34 zoom">
                  <validation-provider
                    name="Company Name"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <label for="#">
                      {{ $t('auth.step1.label-1') }}
                      <span class="required">*</span>
                     
                    </label>
                    <input spellcheck="true"
                      v-model.trim="form.company.name"
                      @keyup="() => $refs.companySearch.setVisible(true)"
                      name="Company Name"
                      autocomplete="off"
                      :class="{
                        'is-invalid':
                          !getValidationState(validationContext) &&
                          validationContext.errors[0],
                      }"
                      type="text"
                      :placeholder="$t('auth.step1.ph-1')"
                      class="form-control"
                    />
                    
                    <company-search 
                        ref="companySearch"
                        v-model="form.company" 
                        :text="form.company.name" 
                    />
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div>
                <!-- Title Select Box -->
                <div class="form-group mb-34 zoom">
                  <validation-provider
                    name="title"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                  


                   
                    <b-form-group
                      id="input-group-3"
                      label-for="input-3"
                     >
                  <template v-slot:label>
                  {{$t('auth.step1.label-2')}} <span class="required">*</span>
                    </template>
 
                  
                      <b-form-select
                        :class="{
                          'is-invalid':
                            !getValidationState(validationContext) &&
                            validationContext.errors[0],
                        }"
                        id="title"
                        v-model="form.title.id"
                        @change="selectTitle"
                        :options="titles"
                        value-field="id"
                        text-field="name"
                        required
                      ></b-form-select>
                      
                    </b-form-group>
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div>
                <!-- Position Select Box -->
                <div v-if="positions.length > 1" class="form-group mb-34 zoom">
                  <validation-provider
                    name="Position"
                    :rules="{ required: positions.length > 0 ? true : false }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="input-group-3"
                     
                      label-for="input-3"
                    >
                     <template v-slot:label>
                  {{$t('auth.step1.label-p')}} <span class="required">*</span>
                    </template>
                    
                      <b-form-select
                        :class="{
                          'is-invalid':
                            !getValidationState(validationContext) &&
                            validationContext.errors[0],
                        }"
                        id="title"
                        v-model="form.position.id"
                        :options="positions"
                        value-field="id"
                        text-field="name"
                        required
                      ></b-form-select>
                    </b-form-group>
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div>
                <div class="form-group mb-34 zoom">
                  <validation-provider
                    name="Industry"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="input-group-3"
                     
                      label-for="input-3"
                    >
                     <template v-slot:label>
                  {{$t('auth.step1.label-3')}} <span class="required">*</span>
                    </template>

                      <b-form-select
                        :class="{
                          'is-invalid':
                            !getValidationState(validationContext) &&
                            validationContext.errors[0],
                        }"
                        id="title"
                        @change="selectIndustry"
                        v-model="form.industry.id"
                        :options="industriesList"
                        value-field="id"
                        text-field="name"
                        required
                      ></b-form-select>
                    </b-form-group>
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div>
                <div class="form-group mb-34 zoom">
                  <validation-provider
                    name="years"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="input-group-3"
                      
                      label-for="input-3"
                    >
                     <template v-slot:label>
                  {{$t('auth.step1.label-4')}} <span class="required">*</span>
                    </template>


                      <b-form-select
                        :class="{
                          'is-invalid':
                            !getValidationState(validationContext) &&
                            validationContext.errors[0],
                        }"
                        id="years"
                        v-model="form.years.id"
                        :options="years"
                        @change="selectYears"
                        value-field="id"
                        text-field="title"
                        required
                      ></b-form-select>
                    </b-form-group>
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div>
                <sweet-modal class="zoom modal-x step1-modal" ref="modal">
                  <h1>
                    {{ $t('auth.step1.modal-title') }}
                  </h1>
                  <hr style="margin-top: -5px; margin-bottom: 24px" />
                  <mapbox-map
                    ref="selectmap"
                    style="height: 450px"
                    :scrollZoom="false"
                    v-if="location.center"
                    access-token="pk.eyJ1IjoiY2FudTkiLCJhIjoiY2tkYW5jNnZjMGpqbzM0cnhheGlhMDJjayJ9.TynY2B9VpBjKI5GOXtSNMA"
                    map-style="mapbox://styles/canu9/ckdanejg91d7o1iqathzm7n5l"
                    :center="location.center"
                    :minZoom="1"
                    :maxZoom="51"
                    :zoom="13"
                    @mb-created="map => (selectMap = map)"
                    @mb-drag="
                      () => (markerpos = selectMap.getCenter().toArray())
                    "
                    @mb-dragend="selectPlace = true"
                  >
                    <mapbox-navigation-control position="top-right" />
                    <mapbox-marker
                      :lng-lat="markerpos"
                      :draggable="false"
                      color="blue"
                    />
                  </mapbox-map>
                  <div class="d-flex mt-4 justify-content-end">
                    <button
                      :disabled="!selectPlace"
                      @click="selectPlaced"
                      class="btn btn-primary"
                    >
                      {{$t("buttons.select")}}
                    </button>
                  </div>
                </sweet-modal>
                <div class="form-groups mb-34 zoom">
                  <label for="#">
                    {{ $t('auth.step1.label-5') }}
                    <span class="required">*</span>
                  </label>
                  <mapbox-geocoder
                    access-token="pk.eyJ1IjoiYmVubnl0cm92YXRvIiwiYSI6ImNrZDcwdTVwbTE4amEyem8yZWdkNHN3ZmoifQ.r3Llqtnwfqqju2zfzE-fvA"
                    types="region,district,place,postcode,locality,neighborhood"
                    @mb-result="onresult"
                  />
                  <small v-if="isEmptyAddress" class="text-danger">
                    {{ $t('auth.step1.alert-5') }}</small
                  >
                </div>
                <div class="form-group mb-34 zoom">
                  <validation-provider
                    name="about"
                    :rules="{ required: true, min: 100, max: 1200 }"
                    v-slot="validationContext"
                  >
                    <label for="#"> {{ $t('auth.step1.label-6') }}
                    <span class="required">*</span>
                    </label>
                    <div class="row mb-4">
                      <div class="col-md-12 about-templates">
                       
          
                        <a
                          :class="[isActive1 ? 'active-template' : '']"
                          @click="template1Content"
                          >{{ $t('auth.step1.temp1') }}</a
                        >
                        <a
                          :class="[isActive2 ? 'active-template' : '']"
                          @click="template2Content"
                          >{{ $t('auth.step1.temp2') }}</a
                        >
                        <a
                          :class="[isActive3 ? 'active-template' : '']"
                          @click="template3Content"
                          >{{ $t('auth.step1.temp3') }}</a
                        >
                        <a
                          :class="[isActive4 ? 'active-template' : '']"
                          @click="template4Content"
                          >{{ $t('auth.step1.temp4') }}</a
                        >
                         <a
                          :class="[isActive ? 'active-template' : '']"
                          @click="writeYourOwn"
                          >{{ $t('auth.step1.sub-label') }}</a 
                        >
                      </div>
                    </div>
                    <textarea spellcheck="true"
                      :class="{
                        'is-invalid':
                          !getValidationState(validationContext) &&
                          validationContext.errors[0],
                      }"
                      v-model="form.about"
                      name
                      id
                      cols="30"
                      rows="10"
                      class="form-control"
                    ></textarea>
                     <div class="counter">
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                    <div class="number-back-counter">
                        {{ counter }} / 1200
                    </div>
                    </div>
                  </validation-provider>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    
      <step-footer v-if="!loading" :disabled="invalid" @jumpin="jumpin()" @submit="handleSubmit(formSubmit)" />
    </validation-observer>
  </div>
</template>

<script>
//markerpos = [e.data.lngLat.lng, e.data.lngLat.lat]
import { toBase64 } from '../../helpers/func';
import industryList from '@/graphql/public/getIndustry.gql';
import GET_POSITIONS from '@/graphql/public/getPositions.gql';
import GET_TITLES from "@/graphql/public/getTitles.gql"
import step2 from '../../graphql/auth/register/step2.gql';
import step3 from '@/graphql/auth/register/step3.graphql';
import step4 from '@/graphql/me/addTags.gql';
import suggestions from '@/graphql/me/profile/keywords.gql';
import updateOnboarding from '@/graphql/me/updateOnboarding.gql';
import loading from '../../components/global/loading';
import stepFooter from '../../components/register-step/step-footer';
import checkStep from '@/graphql/auth/checkStep.gql';
import companySearch from '@/components/global/companySearch.vue';
import StepHeader from '@/components/register-step/step-header.vue';
import { mapGetters, mapMutations } from 'vuex';
import axios from 'axios';
import _ from 'lodash';
import { decode } from 'js-base64';
export default {
  components: {
    loading,
    stepFooter,
    companySearch,
    StepHeader
  },
  data() {
    return {
      isActive: false,
      isActive1: false,
      isActive2: false,
      isActive3: false,
      isActive4: false,
      isEmptyAddress: false,
      selectMap: null,
      location: {},
      markerpos: [],
      city: '',
      place: '',
      markers: [],
      titleNameFirst: 'Owner',
      maxAbout: 650,
      remainingAbout: 650,
      avatarFile: null,
      loading: false,
      value: null,
      positions: [],
      industriesList: [],
      enindustries: [],
      enpositions: [],
      entitles: [],
      titles: [],
      avatar: 'require("@/assets/img/no-avatar.png")',
      years: [
        {
          id: null,
          title: this.$t('auth.step1.ph-4'),
        },
        {
            id: '1-3',
            title: "1-3 Years",
        },
        {
            id: '4-7',
            title: "4-7 Years",
        },

        {
            id: '8-14',
            title: "8-14 Years",
        },
        {
            id: '15-25',
            title: "15-20 Years",
        },
        {
            id: '21-25',
            title: "21-25 Years",
        },
        {
            id: '26-35',
            title: "26-35 Years",
        },
        {
            id: '36-+',
            title: "36+ Years",
        },
      ],
      aboutTitleName: '',
      selectPlace: false,
      enform: {
          position: '',
          industry: '',
          title: '',
      },
      form: {
        avatar: null,
        company: {
          name: null,
          key: null
        },
        position: {
          id: null,
          name: this.$t('auth.step1.ph-p'),
        },
        place: null,
        years: {
          id: null,
          title: this.$t('auth.step1.ph-4'),
        },
        industry: {
          id: null,
          name: this.$t('auth.step1.label-3'),
        },
        title: {
          id: null,
          name: this.$t('auth.step1.ph-2'),
        },
        keyw: null,
        lat: null,
        lng: null,
        about: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getEmail: 'general/getEmail',
      getStep: 'general/getCompleteStep',
    }),
    aboutTemplates() {
      return [
        {
          name: 1,
          content:
            'Hi, I’m a ' +
            this.enform.title +
            ' at ' +
            this.form.company.name +
            ' specialising in the ' +
            this.enform.industry +
            ' industry. Over the course of ' +
            this.form.years.title +
            ' in this sphere, I have built a large network of contacts with suppliers and customers across ' +
            this.location.place_name +
            '.',
        },
        {
          name: 2,
          content:
            'A seasoned professional with a diverse background covering all aspects of the ' +
            this.enform.industry +
            ' ﬁeld over ' +
            this.form.years.title +
            '. As a ' +
            this.enform.title +
            ' at ' +
            this.form.company.name +
            ', I am  experienced in developing and executinng successful strategies by utilising structured derivatives, operating in ' +
            this.location.place_name +
            '.',
        },
        {
          name: 3,
          content:
            'I have over ' +
            this.form.years.title +
            ' of experience working in ' +
            this.enform.industry +
            ' field. Currently, I am a ' +
            this.enform.title +
            ' at ' +
            this.form.company.name +
            ', where I lead a cross-functional team managing customers relations! I’m deeply passionate about selling our trusted products that help our customers to fulﬁl their needs in ' +
            this.location.place_name + ' ' +
            'area.',
        },
        {
          name: 4,
          content:
            'I am a ' +
            this.enform.title +
            ' with a demonstrated history of working in ' +
            this.enform.industry +
            ' industry. Results-driven professional with ' +
            this.form.years.title +
            'of experience on international business operating in ' +
            this.location.place_name +
            '. At ' +
            this.form.company.name +
            ', we thrive to create direct strategic initiatives to achieve organisational objectives and strengthen our business through implementation of cost eﬃciency in operations and administration.',
        },
      ];
    },
    counter() {
        return _.size(this.form.about);
    }
  },
  methods: {
    template1Content() {
      this.form.about = this.aboutTemplates[0].content;
      this.isActive = false;
      this.isActive1 = true;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = false;
      //this.form.about = null;
    },

    template2Content() {
      this.form.about = this.aboutTemplates[1].content;
      this.isActive = false;
      this.isActive1 = false;
      this.isActive2 = true;
      this.isActive3 = false;
      this.isActive4 = false;
      //this.form.about = null;
    },

    template3Content() {
      this.form.about = this.aboutTemplates[2].content;
      this.isActive = false;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = true;
      this.isActive4 = false;
      //this.form.about = null;
    },

    template4Content() {
      this.form.about = this.aboutTemplates[3].content;
      this.isActive = false;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = true;
      // this.form.about = null;
    },

    writeYourOwn() {
      this.form.about = null;
      this.isActive = true;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = false;
    },

    async getLinkedAvatar() {
      this.avatar = 'https://app.connectter.com/img/no-avatar.png';
      if (this.$route.query.q) {
        let parsedLinkedin = JSON.parse(decode(this.$route.query.q));
        let config = { responseType: 'blob' };
        this.avatarFile = await axios
          .get(parsedLinkedin.avatar, config)
          .then(response => {
            return new File([response.data], 'avatar.jpeg', {
              type: response.data.type,
            });
          });

        let result = await toBase64(this.avatarFile);
        this.avatar = result;
      }
    },

    ...mapMutations({ SET_COMPLETE_STEP: 'general/setCompleteStep' }),
    ...mapMutations({
        setEmail: 'general/setEmail',
        SET_COMPLETE_STEP: 'general/setCompleteStep',
        SET_TOKEN: 'general/setToken',
        SET_USER_TYPE: 'general/SET_USER_TYPE',
    }),
    selectPlaced() {
      this.isEmptyAddress = false;
      this.$refs.modal.close();
    },

    onresult({ result }) {
      this.location = result;
      this.markerpos = result.center;
      this.$refs.modal.open();
    },

    async selectTitle(selectedOption) {
      this.form.position.id = null;
      this.positions = [];
      this.form.title.name = this.titles.find(x => x.id == this.form.title.id).name;
      this.enform.title = this.entitles.find(x => x.id == this.form.title.id).name;
      this.form.industry.name = this.industriesList.find(x => x.id == this.form.industry.id).name;
      await this.getPoisitons(this.form.title.id);
    },

    async selectIndustry(selectedOption) {
      this.form.industry.name = this.industriesList.find(x => x.id == this.form.industry.id).name;
      this.enform.industry = this.enindustries.find(x => x.id == this.form.industry.id).name;
    },

    async selectYears() {
      await this.$nextTick();
      this.form.years.title = this.years.find(
        x => x.id == this.form.years.id
      ).title;
    },

    async onFileChange(e) {
      let files = e.target.files;
      if (files.length) {
        if (!/image\/(jpeg|jpg|png)/i.test(files[0].type)) {
          return this.$bvToast.toast('Wrong file format!', {
            title: 'Error',
            variant: 'danger',
            solid: true,
          });
        }

        this.avatarFile = files[0];
        let result = await toBase64(files[0]);
        this.avatar = result;
      }
    },
    click: async function(e) {
      this.selectPlace = false;
      let resp = await axios.get(
        'https://api.mapbox.com/geocoding/v5/mapbox.places/' +
          e.lngLat.lng +
          ',' +
          e.lngLat.lat +
          '.json?access_token=pk.eyJ1IjoiY3VjdXgiLCJhIjoiY2tldHdyem85MDZlNzJ6dGprNzJ4NHlmZCJ9.dPNzRA7M62TExvuixoh-8A'
      );
      this.selectPlace = true;
      let features = resp.data.features;
      if (features && features[0]) {
        this.markerpos = features[0].center;
        this.location = features[0];
        this.place = features[0].properties.address;
        this.city =
          features[0].context.length > 2
            ? features[0].context[1].text
            : features[0].context[0].text;
      }
    },
    countdownAbout() {
      this.remainingAbout = this.maxAbout - this.form.about.length;
    },
    closeSelect(value, id) {
      if (id == 'industry') {
        if (!this.form.industry) {
          let msg = this.$t('validations.required', { _field_: id });
          return this.$refs.observer.setErrors({
            Industry: [msg],
          });
        }
      }
      if (id == 'years') {
        if (!this.form.years) {
          let msg = this.$t('validations.required', { _field_: id });
          return this.$refs.observer.setErrors({
            years: [msg],
          });
        }
      }
      if (id == 'positions') {
        if (!this.form.position) {
          let msg = this.$t('validations.required', { _field_: id });
          return this.$refs.observer.setErrors({
            Positions: [msg],
          });
        }
      }
    },

    async jumpin() {
        try {
            //step 1
            if (this.avatarFile === null) {
                return (
                    this.$bvToast.toast(
                        this.$t("Toaster.profile-picture"),
                        {
                            title: this.$t("Toaster.error-title"),
                            variant: 'danger',
                            autoHideDelay: 1350,
                        }
                    )
                );
            }
            if (this.markerpos.length === 0) {
                return (
                    this.$bvToast.toast(
                        this.$t("Toaster.location-required"), 
                        {
                            title: this.$t("Toaster.error-title"),
                            variant: 'danger',
                            autoHideDelay: 1350,
                        }
                    )
                );
            }

        this.loading = true;
        let variable = {
            avatar: this.avatarFile,
            email: this.$store.state.general.email,
            company_name: this.form.company.name,
            company_key: this.form.company.key,
            title_id: this.form.title.id,
            industry_id: this.form.industry.id,
            years_of_experience: this.form.years.id,
            location: {
                lat: this.markerpos[1],
                lon: this.markerpos[0],
            },
            about: this.form.about,
        };

        if (this.positions.length !== 1) {
            variable.position_id = this.form.position.id;
        }

        await this.$apollo.mutate(step2, variable);
        //step1
        //---------------------------------------------
        // step 2
        let type = ['SELLER', 'BUYER'][_.random(0, 1)];
        let s3Vars = {
            email: this.$store.state.general.email,
            type: type,
        };

        let data = await this.$apollo.mutate(step3, s3Vars);
        let response = data('**.registerStep3');
        this.SET_TOKEN(response.token.token);
        this.SET_COMPLETE_STEP(3);
        this.SET_USER_TYPE(response.member.type);
        this.setEmail(response.member.email);
        this.$io.refresh();
        await this.$store.dispatch(
            'checkToken', 
            { 
                vm: this, 
                par: { 
                    token: response.token.token 
                }, 
                refresh: true 
            }
        );
        // step2

        //step4
        let keywords = [];
        let selector = await this.$apollo.query(suggestions, { tags: [this.GET_USER_INFO.industry.name] });
        keywords.push(...selector('**.suggestions'));
        keywords.push(this.GET_USER_INFO.industry.name);
        await this.$apollo.mutate(step4, { tags: keywords });
        await this.$apollo.mutate(updateOnboarding);
        await this.$store.dispatch(
            'checkToken', 
            { 
                vm: this, 
                par: { 
                    token: response.token.token 
                }, 
                refresh: true 
            }
        );
        this.$router.push('/landing');
        //step4
      } catch (e) {
            this.$bvToast.toast(
                this.$t(e.message), 
                {
                    title: this.$t("Toaster.error-title"),
                    variant: 'danger',
                    autoHideDelay: 1350,
                }
            );
      } finally {
        this.loading = false;
      }  
    },

    async formSubmit() {
      try {
        if (this.avatarFile === null) {
          return this.$bvToast.toast(this.$t("Toaster.profile-picture"),{
            title: this.$t("Toaster.error-title"),
            variant: 'danger',
            autoHideDelay: 1350,
          });
        }
        if (this.markerpos.length === 0) {
          return this.$bvToast.toast(this.$t("Toaster.location-required"), {
            title: this.$t("Toaster.error-title"),
            variant: 'danger',
            autoHideDelay: 1350,
          });
        }

        this.loading = true;
        let variable = {
          avatar: this.avatarFile,
          email: this.$store.state.general.email,
          company_name: this.form.company.name,
          company_key: this.form.company.key,
          title_id: this.form.title.id,
          industry_id: this.form.industry.id,
          years_of_experience: this.form.years.id,
          location: {
            lat: this.markerpos[1],
            lon: this.markerpos[0],
          },
          about: this.form.about,
        };
        if (this.positions.length !== 1) {
          variable.position_id = this.form.position.id;
        }

        await this.$apollo.mutate(step2, variable);
        this.SET_COMPLETE_STEP(2);
        this.$router.push('/register-steps/step-2');
      } catch (e) {
        this.$bvToast.toast(this.$t(e.message), {
           title: this.$t("Toaster.error-title"),
          variant: 'danger',
          autoHideDelay: 1350,
        });
      } finally {
        this.loading = false;
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      if (dirty || validated) {
        return valid;
      }

      return null;
    },

    async getTitles() {
      try {
        let data = await this.$store.dispatch('inquiries/getTitles', {
          vm: this,
        });
        let response = data('**.titles');
        this.titles = response;
        this.titles.unshift({ id: null, name:  this.$t('auth.step1.ph-2') });
        let selector = await this.$apollo.query(GET_TITLES, {}, { headers: { 'X-LANGUAGE': 'EN' } });
        this.entitles = selector('**.titles');
      } catch (e) {}
    },
    async getIndustry() {
      try {
        let par = {
          type: 'MEMBER',
        };
        let data = await this.$apollo.query(industryList, par);
        this.industriesList = data('**.industries');
        this.industriesList.unshift({ id: null, name: this.$t('auth.step1.ph-3') });
        let selector = await this.$apollo.query(industryList, par, { headers: { 'X-LANGUAGE': 'EN' } });
        this.enindustries = selector('**.industries');
      } catch (e) {
      } finally {
      }
    },
    async getPoisitons(id) {
      try {
        let params = {
          title_id: id,
        };

        let data = await this.$apollo.query(GET_POSITIONS, params);
        this.positions = data('**.positions');
        this.positions.unshift({ id: null, name:this.$t('auth.step1.ph-p') });
        let selector = await this.$apollo.query(GET_POSITIONS, params, { headers: { 'X-LANGUAGE': 'EN' } });
        this.enpositions = selector('**.positions');
      } catch (e) {
      } finally {
      }
    },
    async getStepData() {
      try {
        let checkVariable = {
          email: this.getEmail,
        };
        let data = await this.$apollo.query(checkStep, checkVariable);
        let response = data('**.checkStep');
        if (response.about) {
          this.form.about = response.about;
          this.avatar = `${response.avatar}?size=100x100`;
          this.form.company.name = response.company.name;
          let handler = this.years.find(
            item =>
              item.id ===
              response.years_of_experience.low +
                '-' +
                response.years_of_experience.high
          );
          this.form.years = handler;
          let position = this.positions.find(
            item => item.id === response.position_id
          );
          this.form.position = position;
          let industry = this.industriesList.find(
            item => item.id === response.industry_id
          );
          this.form.industry = industry;
        }
      } catch (e) {
      } finally {
      }
    },
  },
  async created() {
    this.getLinkedAvatar();
    this.loading = true;
    await this.getIndustry();
    await this.getTitles();
    await this.getStepData();
    this.writeYourOwn();
    this.loading = false;

    if (this.getStep > 1) {
      return this.$router.push('/register-steps/step-2');
    }

    /*this.template1Content();
    this.template2Content();
    this.template3Content();
    this.template4Content(); */
  },
};
</script>